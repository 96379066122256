.article-block {
    min-height: 50px;
    padding: 0.5rem 0;
}

.social-media-icon-logo {
    width: 24px;
}

.social-media-icon-logo-big {
    width: 50px;
}

.social-media-youtube-wrapper {
    display: inline-block;
    width: 320px;
    height: 240px;
    overflow: hidden;
    position: relative;
}

.social-media-youtube-film {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.social-media-youtube-play {
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 25px);
    width: 50px;
    opacity: 0.75;
}

.social-media-youtube-film:hover + .social-media-youtube-play {
    opacity: 1;
}

.link-text-left {
    text-align: left !important;
}
