.global-accessible .accessible-font-2 *:not(.accessibility-icon) {
    font-family: sans-serif !important;
    font-size: 2rem !important;
}

.global-accessible .accessible-font-2 .article-block h1 {
    font-size: 3rem !important;
}

.global-accessible .accessible-font-3 *:not(.accessibility-icon) {
    font-family: sans-serif !important;
    font-size: 3rem !important;
}

.global-accessible .accessible-font-3 .article-block h1 {
    font-size: 4rem !important;
}

.global-accessible .accessible-spaces-2 *:not(.accessibility-icon) {
    letter-spacing: 3px !important;
    word-spacing: 6px !important;
    line-height: 175% !important;
}

.global-accessible .accessible-spaces-3 *:not(.accessibility-icon) {
    letter-spacing: 6px !important;
    word-spacing: 12px !important;
    line-height: 250% !important;
}

.global-accessible .accessible-contrast-2 {
    background-color: black !important;
}

.global-accessible .accessible-contrast-2 * {
    background-color: black !important;
    color: yellow !important;
}

.global-accessible .accessible-contrast-2 .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' stroke='yellow' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.global-accessible .accessible-contrast-2 .navbar-toggler {
    border: 1px solid yellow;
    margin-bottom: 5px;
}
.global-accessible .accessible-contrast-2 .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='yellow' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.global-accessible .accessible-contrast-3 {
    background-color: white !important;
}

.global-accessible .accessible-contrast-3 * {
    background-color: white !important;
    font-weight: bold;
    color: black !important;
}

.global-accessible .accordion-button:focus,
.global-accessible .navbar-light .navbar-nav .nav-link:focus {
    border: 2px solid lightgrey !important;
    border-radius: 15px !important;
}

.global-accessible .accessibility-trigger *,
.global-accessible .accessibility-icon * {
    max-width: 40px;
    max-height: 40px;
    background-color: white !important;
    padding: 0px;
    border-radius: 5px;
}

.global-accessible .accessibility-trigger * {
    border: 1px solid black;
    padding: 10px;
    max-width: 60px;
    max-height: 60px;
}

.global-accessible .accessibility-icon {
    background-color: lightgrey !important;
}

.global-accessible .accessibility-icon {
    border: 1px solid black !important;
    margin-left: 10px;
    margin-right: 10px;
    background-color: white !important;
}

.global-accessible .accessible-contrast-2 .accessibility-icon {
    border: 10px solid black !important;
}

@media only screen and (min-width: 1400px) {
    .global-accessible .right-content-block {
        padding-left: 200px !important;
        padding-right: 180px !important;
        list-style-position: inside;
    }
}

@media only screen and (min-width: 768px) {
    .global-accessible .article-image-container-wide {
        width: 100%;
    }
}
