.modal-image-arrow {
    font-size: 50px !important;
    outline: none !important;
    box-shadow: none !important;
    color: #000;
    opacity: 0.3;
}

button.modal-image-arrow:focus {
    outline: 1px solid grey !important;
}
